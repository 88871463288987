import {extendTheme} from '@chakra-ui/react';

export const theme = extendTheme({
  fonts: {
    body: 'Inter, system-ui, sans-serif',
    heading: 'Work Sans, system-ui, sans-serif',
  },
  colors: {
    blue: {
      500: '#4468AD'
    }
  }
})
