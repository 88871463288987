import {captureRemixErrorBoundaryError} from "@sentry/remix";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useLocation,
  useMatches,
  useRouteError,
} from "@remix-run/react";
import {json, LinksFunction, LoaderFunctionArgs, MetaFunction} from '@remix-run/node';
import Header from '~/components/layout/Header';
import styles from "./index.css?url"
import Footer from '~/components/layout/Footer';
import {Box, Flex} from '@styled-system/jsx';
import {ReactNode, useEffect} from 'react';
import process from 'process';
import {assertEnvVar} from '~shared/utils/env-utils.server';
import {PayPalScriptProvider} from "@paypal/react-paypal-js";
import {theme} from './chakra-theme';
import {ChakraProvider} from '@chakra-ui/react';
import {authenticate} from '~/services/auth.server';
import {analytics} from 'customer/app/components/CustomerIoTracker';

export const links: LinksFunction = () => {
  return [
    {rel: 'preconnect', href: 'https://fonts.googleapis.com'},
    {rel: 'preconnect', href: 'https://fonts.gstatic.com'},
    {
      rel: 'stylesheet',
      href: 'https://fonts.googleapis.com/css2?family=DM+Sans:wght@100..1000&family=Oswald:wght@200..700&display=swap'
    },
    {rel: "stylesheet", href: styles},
  ]
}

export const meta: MetaFunction = () => {
  return [
    {title: 'Fanathem'}
  ]
}

export const loader = async ({request}: LoaderFunctionArgs) => {
  return json({
    user: await authenticate(request),
    ENV: {
      NODE_ENV: process.env.NODE_ENV,
      GITHUB_SHA: process.env.GITHUB_SHA,
      PAYPAL_CLIENT_ID: assertEnvVar('PAYPAL_CLIENT_ID'),
      NEXIO_PUBLIC_KEY: assertEnvVar('NEXIO_PUBLIC_KEY'),
    },
  })
}

export const Layout = ({children}: { children: ReactNode }) => {
  return (
    <html lang="en">
    <head>
      <meta charSet="utf-8"/>
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"/>
      <Meta/>
      <Links/>
    </head>
    <body>
    {children}
    <ScrollRestoration/>
    <Scripts/>
    {/*<HighLevelChatWidget/>*/}
    </body>
    </html>
  );
};

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return <div>Something went wrong</div>;
};

export default function App() {
  const matches = useMatches();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const skipLayout = matches.some(m => (m.handle as any)?.skipLayout || (m.data as any)?.skipLayout);

  const data = useLoaderData<typeof loader>();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const custId = searchParams.get('cust_id');

  useEffect(() => {
    analytics.page();
  }, [location]);

  useEffect(() => {
    if (custId) {
      analytics.identify(custId);
    }
  }, [custId]);

  const page = skipLayout ? (
    <Outlet/>
  ) : (
    <Flex
      minHeight="100vh"
      flexDirection="column"
      width="full"
    >
      <Header user={data.user}/>
      <Box flex={1}>
        <Outlet/>
      </Box>
      <Footer/>
    </Flex>
  );

  return <ChakraProvider theme={theme} resetCSS={false}>
    <PayPalScriptProvider
      deferLoading={true}
      options={{
        clientId: data.ENV.PAYPAL_CLIENT_ID
      }}
    >
      <script
        dangerouslySetInnerHTML={{
          __html: `window.ENV = ${JSON.stringify(
            data.ENV ?? {}
          )}`,
        }}
      />
      {page}
    </PayPalScriptProvider>
  </ChakraProvider>
}
